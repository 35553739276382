if (!Object.groupBy) {
    Object.groupBy = function (array, callback) {
        return Array.from(array).reduce((result, item) => {
            const key = callback(item);
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(item);
            return result;
        }, {});
    };
}